import React from 'react';
import './App.scss';

function App() {
  return (
    <div className="App">
      <section className="section1">
        <h1>COMING SOON</h1>
      </section>
      <section className="section2">
        <div className="section2__subsection">
          <h1>We're Simple&nbsp;Origin.</h1>
          <p>We're in the people improvement business.</p>
          <p>We're working to reshape the personal wellness landscape - helping people feel energized, enabled, and empowered.</p>
          <p>This is only the beginning!
            <br/>
            We need top-notch talent to help us stomp into the next phase of growth as a company.
          </p>
        </div>
      </section>
      <section className="section3">
        <h1>If the below qualities describe you&nbsp;...</h1>
        <p>- Quick thinker, quicker worker</p>
        <p>- Innovative ideator</p>
        <p>- Endlessly hungry for elevating brands</p>
        <p>- Always looking for a good industry disruption</p>
        <h1>Then click the link below to view current openings.</h1>
      </section>
      <section className="section4">
        <p>If you don't see a position that specifically fits your skills 
          <br className="disappearingBreak"/>
        but you know we need you, convince us at <a href="mailto:careers@simpleorigin.com">careers@simpleorigin.com</a></p>
      </section>
      <section className="section5">
        <button>Learn More</button>
      </section>
    </div>
  );
}

export default App;
